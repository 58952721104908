import nodeListForEach from './node-list-for-each';
import headerScroll from './header';
import sliders from './sliders';
import menuOpen from './menu-open';
import modal from './modal';
import tel from './tel';
import btnUp from './btn-up';
import numeral from './numeral';
import youtubePopup from './youtube-popup';
import accordion from './accordion';
import ankors from './ankors';
import aside from './aside';

class App {
  static init() {
    nodeListForEach();
    headerScroll();
    sliders();
    menuOpen();
    modal();
    tel();
    btnUp();
    numeral();
    youtubePopup();
    accordion();
    ankors();
    aside();
  }
}


App.init();
window.App = App;
