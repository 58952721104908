const aside = () => {
  const btn = $(".aside-btn");

  if(btn) {
    $(window).scroll(function() {
      if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
          btn.addClass("d-none");
      } else {
        btn.removeClass("d-none");
      }
    });
  }
};

export default aside;
