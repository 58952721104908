const numeral = () => {

  const section = document.querySelector(".js-numeral");

  if (section) {
    $(document).ready(function(){
      let show = true;
      let countbox = ".js-numeral";
      $(window).on("scroll load resize", function () {
          if (!show) return false; // Отменяем показ анимации, если она уже была выполнена
          let w_top = $(window).scrollTop(); // Количество пикселей на которое была прокручена страница
          let e_top = $(countbox).offset().top; // Расстояние от блока со счетчиками до верха всего документа
          let w_height = $(window).height(); // Высота окна браузера
          let d_height = $(document).height(); // Высота всего документа
          let e_height = $(countbox).outerHeight(); // Полная высота блока со счетчиками
          if (w_top + 500 >= e_top || w_height + w_top == d_height || e_height + e_top < w_height) {
              $(".js-numeral-number").spincrement({
                  thousandSeparator: "",
                  duration: 2500
              });

              show = false;
          }
      });
    });
  }
};

export default numeral;
