const menuOpen = () => {
  // Открытие моб меню
  const $buttonsMenu = $(".js-open-menu");

  if ($buttonsMenu.length) {
    const $menu = $(".menu");
    const $buttonClose = $(".js-btn-close");
    const $header = $(".header");

    $buttonsMenu.each(function () {
      const $btn = $(this);

      $btn.click(function() {
        // если открыто меню
        if ($menu.hasClass("is-show")) {

          const pos = parseInt($("body").attr("data-scroll"), 10);
          $menu.removeClass("is-show");
          $btn.removeClass("is-show");
          $header.removeClass("reset-bg");
          $btn.blur();

          $("body").removeClass("is-menu-open").removeAttr("data-scroll");
          window.scrollTo(0, pos);

          // если закрыто меню
        } else {

          $menu.addClass("is-show");

          setTimeout(function () {
            $btn.addClass("is-show");

          }, 200);

          setTimeout(function () {
            $header.addClass("reset-bg");

          }, 400);

          setTimeout(function () {
            const pagePos = $(window).scrollTop();
            $("body").addClass("is-menu-open").attr("data-scroll", pagePos);
          }, 450);
        }
      });
    });
  }

};

export default menuOpen;
