const ankors = () => {
  const links = $(".js-ankor");
  if (!links) {
    return;
  }

  //Проверяем на document.ready наличие #hashtag в url, и если есть, скроллим до нужной секции
  const checkHash = function() {
    const hash = window.location.hash;

    if (hash) {
      $('html, body').animate({
          scrollTop: ($(hash).offset().top - 130),
      }, 900, 'swing');
    }
  };

  $(document).ready(checkHash);

  // На кнопки вешаем обработчики событий
  links.each(function() {
    $(this).on("click", function() {
      var href  = $(this).attr('href');
      $('html, body').animate({
        scrollTop: ($(href).offset().top - 130),
    }, 900, 'swing');
    });
  });

};

export default ankors;
