const youtubePopup = () => {
  // Видео в модальном окне
  const videoBtn = document.querySelector(".js-btn-video");

  if (videoBtn) {
    $(".js-btn-video").grtyoutube();
  }
};

export default youtubePopup;
