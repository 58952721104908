const sliders = () => {
  const Swiper = window.Swiper;

  // Slider production
  const production = document.querySelector(".js-production-slider");

  if (production) {
    const mySwiper = new Swiper(".js-production-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      pagination: {
        el: ".js-production-slider .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".js-production-slider .swiper-button-next",
        prevEl: ".js-production-slider .swiper-button-prev",
      }
    });
  }

  // Slider photos
  const photos = document.querySelector(".js-photos-slider");

  if (photos) {
    const mySwiper = new Swiper(".js-photos-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 10,
      speed: 500,
      pagination: {
        el: ".js-photos-slider .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".js-photos-slider .swiper-button-next",
        prevEl: ".js-photos-slider .swiper-button-prev",
      },
      breakpoints: {
        570: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        870: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }

  // Slider images
  const images = document.querySelector(".js-images-slider");

  if (images) {
    const mySwiper = new Swiper(".js-images-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 10,
      speed: 500,
      pagination: {
        el: ".js-images-slider .swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".js-images-slider .swiper-button-next",
        prevEl: ".js-images-slider .swiper-button-prev",
      },
      breakpoints: {
        570: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
  }

  // Slider goods
  const goods = document.querySelector(".js-goods-slider");

  if (goods) {
    const mySwiper = new Swiper(".js-goods-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      navigation: {
        nextEl: ".js-goods-slider .swiper-button-next",
        prevEl: ".js-goods-slider .swiper-button-prev",
      }
    });

    let totalSlides = 0;
    const totalSlidesSpan = goods.querySelector(".js-all-slides");
    const currentSlideSpan = goods.querySelector(".js-current-slide");
    const slides = goods.querySelectorAll(".swiper-slide");
    const btns = goods.querySelectorAll(".side-block__btn");
    const titleSpan = goods.querySelector(".js-title-span");
    const textSpan = goods.querySelector(".js-text-span");

    // Определяем сколько вообще у нас слайдов и записываем в span
    for (let i = 0; i < slides.length; i++) {
      totalSlides += 1;
    }

    totalSlidesSpan.textContent = totalSlides;

    // Определяем номер текущего слайда при нажатии кнопок
    function sliderStateChange() {
        let index = mySwiper.realIndex;
        currentSlideSpan.textContent = index + 1;

        let title = slides[index].getAttribute("data-title");
        let text = slides[index].getAttribute("data-text");
        titleSpan.textContent = title;
        textSpan.textContent = text;

    }
    sliderStateChange();

    btns.forEach(function(btn) {
      btn.addEventListener("click", sliderStateChange);
    });

    // Делаем это на изменение слайда
    mySwiper.on('transitionStart', sliderStateChange);
  }
};

export default sliders;
